import React from "react"
import { useForm, Controller } from "react-hook-form"
import {
  Box,
  OutlinedInput,
  InputLabel,
  FormControl,
  FormHelperText,
  makeStyles,
  Grid,
  Container,
  Button,
  Typography,
} from "@material-ui/core"
import "../styles/homepage-v1.scss"
import LogotypeImg from "../images/99rises-logotype-350x102px.png"

const useStyles = makeStyles(theme => ({
  error: {
    color: "red",
    textAlign: "left",
  },
  textFieldError: {
    padding: "0 14px 0 14px",
    color: "red",
  },
  btn: {
    backgroundColor: "#1685d3 !important",
    borderRadius: "4px !important",
    width: "100%",
    height: "50px",
    color: "white",
  },
}))

export default function WaitlistSignup() {
  const classes = useStyles()
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()
  const onSubmit = data => {
    console.log(data)
    window.location.assign("/thankyou")
  }

  return (
    <div className="container">
      <img src={LogotypeImg}></img>

      <Box mb={3}>
        <Typography variant="h5">
          Bringing hedge fund and alternative investment strategies to everyone
        </Typography>
      </Box>
      <Container maxWidth="sm">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Box mb={3} mr={2}>
                <FormControl
                  variant="outlined"
                  style={{ width: "100%" }}
                  className="formcontrol-name"
                >
                  <InputLabel className="inputlabel first_name_label">
                    First Name
                  </InputLabel>
                  <Controller
                    className="outlinedinput-label"
                    name="firstName"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <OutlinedInput labelWidth={80} {...field} />
                    )}
                    rules={{ required: "First name can't be empty." }}
                  />
                  <FormHelperText className={classes.textFieldError}>
                    {errors?.firstName?.message}
                  </FormHelperText>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Box mb={3}>
                <FormControl
                  variant="outlined"
                  style={{ width: "100%" }}
                  className="formcontrol-name"
                >
                  <InputLabel className="inputlabel last_name_label">
                    Last Name
                  </InputLabel>
                  <Controller
                    className="outlinedinput-label"
                    name="lastName"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <OutlinedInput labelWidth={80} {...field} />
                    )}
                    rules={{ required: "Last name can't be empty." }}
                  />
                  <FormHelperText className={classes.textFieldError}>
                    {errors?.lastName?.message}
                  </FormHelperText>
                </FormControl>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box mb={3}>
                <FormControl
                  variant="outlined"
                  style={{ width: "100%" }}
                  className="formcontrol-name"
                >
                  <InputLabel className="inputlabel Email_label">
                    Email
                  </InputLabel>
                  <Controller
                    className="outlinedinput-label"
                    name="email"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <OutlinedInput labelWidth={80} {...field} />
                    )}
                    rules={{
                      validate: {
                        required: value => value !== null && value !== "",
                        valid: value => {
                          return /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g.test(
                            value
                          )
                        },
                      },
                    }}
                  />
                  <FormHelperText className={classes.error}>
                    {errors.email?.type === "required" && "Email is Required."}
                    {errors.email?.type === "valid" && "Invalid Email."}
                  </FormHelperText>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box mb={3}>
                <FormControl
                  variant="outlined"
                  style={{ width: "100%" }}
                  className="formcontrol-name"
                >
                  <InputLabel className="inputlabel Email_label">
                    Mobile number
                  </InputLabel>
                  <Controller
                    className="outlinedinput-label"
                    name="phoneNumber"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <OutlinedInput labelWidth={80} {...field} />
                    )}
                    rules={{
                      validate: {
                        required: value => value !== null && value !== "",
                        pattern: value => {
                          return /^[1-9]\d*(\d+)?$/i.test(value)
                        },
                        maxNumber: value => {
                          return (
                            `${value}`
                              .replace(/[()_-]/g, "")
                              .split(" ")
                              .join("").length === 10
                          )
                        },
                      },
                    }}
                  />
                  <FormHelperText className={classes.error}>
                    {errors.phoneNumber?.type === "required" &&
                      "Mobile number is Required."}
                    {errors.phoneNumber?.type === "maxNumber" &&
                      "Mobile number should be of 10 digits"}
                    {errors.phoneNumber?.type === "pattern" &&
                      "Mobile number should be of digits only."}
                  </FormHelperText>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
          <Button
            variant="contained"
            className={classes.btn}
            type="submit"
            style={{}}
          >
            Join Waitlist
          </Button>

          <div>© 2021 99rises. All rights reserved</div>
        </form>
      </Container>
    </div>
  )
}
